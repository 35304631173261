/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    blockquote: "blockquote",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Precios"), "\n", React.createElement("table", {
    style: {
      width: '100%'
    }
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Una mascota"), "\n", React.createElement("th", null, "Rostro"), "\n", React.createElement("th", null, "Cuerpo entero"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "Digital(pdf)"), "\n", React.createElement("td", null, "55€"), "\n", React.createElement("td", null, "75€")), React.createElement("tr", null, React.createElement("td", null, "Lámina 20x30 (A4)"), "\n", React.createElement("td", null, "75€"), "\n", React.createElement("td", null, "95€")), React.createElement("tr", null, React.createElement("td", null, "Lámina 30x42 (A3)"), "\n", React.createElement("td", null, "85€"), "\n", React.createElement("td", null, "105€")), React.createElement("tr", null, React.createElement("td", null, "Lámina 42x60 (A2)"), "\n", React.createElement("td", null, "100€"), "\n", React.createElement("td", null, "120€")))), "\n", React.createElement(_components.h3, null, "¿Quieres algún dibujo?"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Puedes ver más y contactar conmigo en ", React.createElement(_components.a, {
    href: "https://www.instagram.com/lady_toxiclake/"
  }, "Instagram")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
